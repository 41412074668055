<!-- eslint-disable vue/multi-word-component-names -->
<script>
import Filtro01 from '@/components/shared/relatorio/filtros/Filtro01.vue'

export default {
  extends: Filtro01,

  data() {
    return {
      modulo: 'consignataria',
    }
  },

  methods: {
    carregarListaConsignatarias() {
      this.consignatariaService.getConsignatariaLogada().then((res) => {
        this.consignatarias = res
      })
    },
  },
}
</script>
